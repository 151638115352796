@import "./variables.less";

@size: 20px;

.button {
  .hover-expand-background((@size + @spacing[m]), @white);

  z-index: @z-index[above];
  position: relative;
  width: @size;
  height: @size;
}

.line {
  content: "";
  position: absolute;
  height: 2px;
  left: 0;
  top: 0;
  background: @action-primary-background;
  border-radius: 2px;
}

@top: 3px;
@middle: (@size / 2);
@bottom: (@size - @top);

.line1 {
  width: 100%;
  transform: translateY(@top);
}

.line2 {
  width: 80%;
  transform: translateY(@middle);
}

.line3 {
  width: 100%;
  transform: translateY(@bottom);
}

.white {
  &.button {
    .hover-expand-background((@size + @spacing[m]), @action-primary-background);
  }

  .line {
    background: @white;
  }
}

.visuallyHidden {
  .visually-hidden();
}
