@import "../../common/styles/variables.less";

// padding defined multiple places so padding around
// interactive elements are also clickable
@alert-padding-vertical: @spacing[xs];

.container {
  position: relative;
  z-index: @z-index[header];
  background-color: @grey[800];

  > *:not(:last-child) {
    border-bottom: solid 1px @grey[600];
  }
}

.alert {
  display: grid;
  grid-template-columns: 20px 1fr 20px;
  position: relative;
  color: @white;
  text-align: center;

  &.static {
    grid-template-columns: 1fr;
  }
}

.content {
  .font(s, normal);

  display: block;
  padding: @alert-padding-vertical 0;
  max-width: 700px; // Keep long alerts easy to read on large screens
  margin: 0 auto;
}

.link {
  .content {
    .link-hover();
  }
}

.dismissButtonArea {
  display: flex;
  justify-content: flex-end;
}

.dismissButton {
  .hover-expand-background(1.6em, @grey[900]);

  // need relative positioning to contain background hover
  position: relative;
  font-size: @font-sizes[l];
  color: @white;
  padding: @alert-padding-vertical @spacing[s];
  // break out of container to maintain right-alignment;
  margin: 0 -@spacing[s];
}

@media @phone-and-smaller {
  .dismissButton {
    font-size: @font-sizes[m];
  }
}
