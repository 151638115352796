@import "./variables.less";

@drawer-padding: @spacing[m];
@close-button-size: @spacing[xl-3];

.container {
  .transition-active(opacity);

  z-index: @z-index[mobile-menu];
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background-color: @modal-overlay-background;

  &-entering,
  &-entered {
    opacity: 1;
  }

  &-exiting,
  &-exited {
    opacity: 0;
  }
}

.box {
  .transition-active(opacity, transform);

  z-index: @z-index[mobile-menu];
  position: relative;
  width: 90%;
  max-width: 375px;
  height: 100%;
  box-shadow: @modal-box-shadow;
  display: flex;
  flex-direction: column;
  background: @modal-background;
  padding: @spacing[xl] 0 @spacing[m];
  border-bottom-right-radius: @modal-border-mobile-radius;
  border-top-right-radius: @modal-border-mobile-radius;

  &-entering,
  &-entered {
    opacity: 1;
    transform: translateX(0%);
  }

  &-exiting,
  &-exited {
    opacity: 0;
    transform: translateX(-120%);
  }
}

.header {
  display: flex;
  gap: @spacing[s];
  justify-content: space-between;
  align-items: center;
  padding: 0 @drawer-padding @spacing[s];
}

.closeButton {
  background: @grey[75];
  width: @close-button-size;
  height: @close-button-size;
  border-radius: @border-radius[s];
}

.headerAuth {
  display: flex;
  gap: @spacing[s];
  justify-content: flex-end;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  position: relative;
}

.scrollBottom,
.scrollTop {
  .transition-active(opacity);

  position: absolute;
  left: 0;
  right: 0;
  height: 60px;
  pointer-events: none;
  opacity: 0;

  &.active {
    opacity: 1;
  }
}

.scrollTop {
  top: 0;
  background: linear-gradient(@white, transparent);
}

.scrollBottom {
  bottom: 0;
  background: linear-gradient(transparent, @white);
}

.searchbar {
  padding: 0 @drawer-padding;
}

.search {
  .input-default();
  .input-text();
  .text-overflow-ellipsis();

  height: @action-height-s;
  border-radius: @action-height-s;
  background: @input-background;
  box-shadow: @input-box-shadow;
  display: flex;
  align-items: center;
  gap: @spacing[s];
  padding: 0 @spacing[m];
  width: 100%;
  text-align: left;

  &:hover {
    .input-hover();
  }

  .icon {
    color: @input-placeholder-color;
  }

  .placeholder {
    .text-overflow-ellipsis();

    flex: 1;
    color: @input-placeholder-color;
  }
}

.links {
  display: flex;
  flex-direction: column;
  gap: @spacing[xl-3];
  flex: 1;
  overflow-y: auto;

  // Use padding instead of gap in the containers so that the scroll appears for the whole
  // visible area
  padding: @spacing[xl-3] @drawer-padding @spacing[m];
}

.main {
  display: flex;
  flex-direction: column;
  gap: @spacing[xl];

  .link {
    .link-hover();
    .font(xl, normal);

    color: @text-primary-color;
  }

  .bigLink {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      flex: 1;
    }

    .label {
      .transition-hover(color);
      .font(xl, normal);

      color: @text-primary-color;
    }

    .description {
      .font(s, normal);

      color: @text-secondary-color;
    }

    &:hover {
      .label {
        color: @link-color;
      }

      .icon {
        .animation-angle-right-hover();
      }
    }
  }
}

.secondary {
  display: flex;
  flex-direction: column;
  gap: @spacing[m];

  .link {
    .link-hover();
    .font(m, normal);

    color: @text-secondary-color;
    text-align: left;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: @divider-border;
  padding: @spacing[s] @drawer-padding 0;

  .link {
    .link-hover();
    .font(xs, normal);

    color: @text-secondary-color;
    display: flex;
    align-items: center;
    gap: @spacing[xs-2];

    &:hover {
      .icon {
        .animation-angle-left-hover();
      }
    }
  }
}
