@import "./variables.less";

.banner {
  padding: @spacing[xs] 0;
}

.content {
  display: grid;
  grid-template-columns: 160px 1fr 160px;
  align-items: center;
}

.message {
  .font(s, normal);

  display: flex;
  align-items: center;
  justify-content: center;
  gap: @spacing[s];

  // Set separately so the tag doesn't get underlined
  .text {
    .transition-hover(color);
  }

  &:hover {
    .stars {
      .animation-icon-wiggle();
    }
  }
}

.shipping {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: @spacing[xs];
}

.home {
  .font(s, normal);

  display: flex;
  align-items: center;
  gap: @spacing[xs-2];
}

.links {
  display: flex;
  gap: @spacing[xs];
  align-items: center;
  justify-content: flex-end;
}

.help {
  .font(s, normal);

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: @spacing[xs-2];

  &:hover {
    .icon {
      .animation-icon-wiggle();
    }
  }
}

.variant- {
  &default {
    background: linear-gradient(
      90deg,
      fade(@white, 10%) 0%,
      fade(@white, 30%) 25%,
      fade(@white, 60%) 50%,
      fade(@white, 30%) 75%,
      fade(@white, 10%) 100%
    );
    color: @text-primary-color;
  }

  &semi-transparent {
    background: rgb(255 255 255 / 15%);
    color: @white;
  }

  &dark {
    background: @grey[900];
    color: @white;
  }
}

@media @tablet-landscape-and-smaller {
  .shipping {
    display: none;
  }
}

@media @tablet-portrait-and-smaller {
  .home,
  .links {
    display: none;
  }

  .content {
    display: flex;
    justify-content: center;
  }
}

@media @phone-and-smaller {
  .long {
    display: none;
  }
}
