// Animations
.animation-icon-wiggle() {
  @keyframes wiggle {
    0% {
      transform: translate3d(0, 0, 0);
    }

    25% {
      transform: translate3d(0, 0, 0);
    }

    50% {
      transform: translate3d(0, 3%, 0);
    }

    75% {
      transform: translate3d(0, -2%, 0);
    }

    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  animation: wiggle 1000ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s infinite
    normal;
}

.animation-angle-up-hover() {
  @keyframes up {
    0% {
      transform: translate3d(0, 0, 0);
    }

    30% {
      transform: translate3d(0, -8%, 0);
    }

    100% {
      transform: translate3d(0, 2%, 0);
    }
  }

  animation: up 1000ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s infinite normal;
}

.animation-angle-down-hover() {
  @keyframes down {
    0% {
      transform: translate3d(0, 0, 0);
    }

    30% {
      transform: translate3d(0, 8%, 0);
    }

    100% {
      transform: translate3d(0, -2%, 0);
    }
  }

  animation: down 1000ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s infinite normal;
}

.animation-angle-right-hover() {
  @keyframes right {
    0% {
      transform: translate3d(0, 0, 0);
    }

    30% {
      transform: translate3d(8%, 0, 0);
    }

    100% {
      transform: translate3d(-2%, 0, 0);
    }
  }

  animation: right 1000ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s infinite normal;
}

.animation-angle-left-hover() {
  @keyframes left {
    0% {
      transform: translate3d(0, 0, 0);
    }

    30% {
      transform: translate3d(-8%, 0, 0);
    }

    100% {
      transform: translate3d(2%, 0, 0);
    }
  }

  animation: left 1000ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s infinite normal;
}
