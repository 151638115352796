@import "../../../common/styles/variables.less";

// Page tokens
@page-primary-background: @grey[50];
@page-secondary-background: @white;
@page-container-width: 1300px;
@page-container-padding: @spacing[m];

// Z-Index
@z-index: {
  behind: -1; // render behind any default flow
  default: 0; // Default to help with new stacking contexts
  above: 1; // For misc content that needs to be above the base page content
  header: 2; // For header elements that need to be above gradients on the page
  select: 3; // For selects that needs to be above the base page content
  mobile-menu: 50; // Mobile menu should be above all content except modals
  chat: 97; // Chat should be behind overlays
  presentation: 98; // when we need to expand to fullscreen over any headers
  dropdown: 99; // dropdowns should lay on top of presentations
  modal: 100; // should render above presentations as the content
  loading-bar: 101; // loading bar should display above everything
  overlay: 102; // when we want to cover the whole page
  standalone-stackable: 103; // should be rare, but should only appear one at a time and above everything else
};

.page-container() {
  width: 100%;
  max-width: @page-container-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: @page-container-padding;
  padding-right: @page-container-padding;
}

@body-padding: @spacing[xl-7] 0;

.break-page-container() {
  // Break out of the page padding to expand content to the edges
  margin-left: -@page-container-padding;
  margin-right: -@page-container-padding;
  padding-left: @page-container-padding;
  padding-right: @page-container-padding;

  // Browsers will ignore padding at the end of a horizontal scroll, so this puts it there
  // See https://chenhuijing.com/blog/flexbox-and-padding/#%F0%9F%8F%80
  &::after {
    content: "";
    padding-right: 0.02px; // Smallest size a browser will recognize
  }

  // Break out of the container when maxed out expand the content to the edges
  // We need to move each side the amount of the (page-width - container-width - page-padding)
  @media screen and (min-width: @page-container-width) {
    margin-left: calc(
      ((100vw - @page-container-width + @page-container-padding) / -2)
    );
    margin-right: calc(
      ((100vw - @page-container-width + @page-container-padding) / -2)
    );
  }
}

.break-page-container-right() {
  .break-page-container();

  // Give extra padding on the left so the content lines up with the container, but scrolling
  // allows it to go to the edge rather than disappear mid-screen
  @media screen and (min-width: @page-container-width) {
    padding-left: calc(
      (100vw - @page-container-width + @page-container-padding) / 2
    );
  }
}

.break-page-container-left() {
  .break-page-container();

  // Give extra padding on the right so the content lines up with the container, but scrolling
  // allows it to go to the edge rather than disappear mid-screen
  @media screen and (min-width: @page-container-width) {
    padding-right: calc(
      (100vw - @page-container-width + @page-container-padding) / 2
    );
  }
}

// Section tokens
.section-rounded-top() {
  border-top-right-radius: @border-radius[xl-2];
  border-top-left-radius: @border-radius[xl-2];

  @media @phone-and-smaller {
    border-top-right-radius: @border-radius[m];
    border-top-left-radius: @border-radius[m];
  }
}

.section-rounded-bottom() {
  border-bottom-right-radius: @border-radius[xl-2];
  border-bottom-left-radius: @border-radius[xl-2];

  @media @phone-and-smaller {
    border-bottom-right-radius: @border-radius[m];
    border-bottom-left-radius: @border-radius[m];
  }
}

.section-rounded() {
  .section-rounded-top();
  .section-rounded-bottom();
}

// Input tokens
// Darker placeholder since most inputs use the label as a placeholder and true placeholders need to
// be more obvious on this side of the app
@input-placeholder-color: @grey[500];
@input-box-shadow: @box-shadow[200];
@input-border-radius: @border-radius[s];
@input-background: @white;
@input-border-color: @grey[200];
@input-border: 1px solid @input-border-color;
@input-hover-background: @white;
@input-hover-border-color: @grey[300];
@input-hover-border: 1px solid @input-hover-border-color;
@input-active-background: @white;
@input-active-border-color: @grey[600];
@input-active-border: 1px solid @input-active-border-color;
@input-active-hover-background: @white;
@input-disabled-background: @grey[75];
@input-text-color: @black;
@input-outline: @primary-background;
@input-placeholder-color: @grey[400];

@action-border-radius: @border-radius[s];

/**
  Resets any input to have 0 default styles
*/
.input-reset() {
  .input-base-reset();
}

.input-default() {
  .transition-hover(background, border-color, box-shadow);

  background: @input-background;
  border: 1px solid @input-border-color;
  border-radius: @input-border-radius;
}

.input-hover() {
  border: 1px solid @input-hover-border-color;
}

.input-focus() {
  outline: none; // handled via the shadow
  border: 1px solid @input-active-border-color;
  box-shadow: 0 0 0 0.5px @input-active-border-color inset;
}

.input-error() {
  border: 1px solid @danger[200];
  box-shadow: 0 0 0 0.5px @danger[200] inset;

  &::placeholder {
    color: @danger[300];
  }
}

.input-error-hover() {
  border: 1px solid @danger[300];
}

.input-error-focus() {
  border: 1px solid @danger[500];
  box-shadow: 0 0 0 0.5px @danger[500] inset;
}

.input-label() {
  .font(s, semi-bold);
  .font-label();

  color: @text-secondary-color;
}

.input-text() {
  .font(m, normal);

  color: @text-primary-color;

  // Don't shrink input text on mobile since iOS will zoom in on inputs with
  // fonts smaller than 16px
  @media @phone-and-smaller {
    font-size: @font-sizes[m];
  }
}

.input-default() {
  .transition-hover(background, border-color, box-shadow);

  background: @input-background;
  border: 1px solid @input-border-color;
  box-shadow: @input-box-shadow;
  border-radius: @input-border-radius;
}

.input-hover() {
  border: 1px solid @input-hover-border-color;
}

.input-focus() {
  outline: none; // handled via the shadow
  border: 1px solid @input-active-border-color;
  box-shadow:
    @input-box-shadow,
    0 0 0 0.5px @input-active-border-color inset;
}

.input-error() {
  border: 1px solid @danger[200];
  box-shadow:
    @input-box-shadow,
    0 0 0 0.5px @danger[200] inset;

  &::placeholder {
    color: @danger[300];
  }
}

.input-error-hover() {
  border: 1px solid @danger[300];
}

.input-error-focus() {
  border: 1px solid @danger[500];
  box-shadow:
    @input-box-shadow,
    0 0 0 0.5px @danger[500] inset;
}

// Actions
@action-height-l: 64px;
@action-height-m: 52px;
@action-height-s: 46px;
@action-height-xs: 40px;
@action-height-xs-2: 32px;

@action-padding: 0 @spacing[m];

@subaction-height-m: 42px;
@subaction-height-s: 36px;
@subaction-height-xs: 30px;

// Chips
@chip-height: {
  s: 20px;
  m: 24px;
};

// Popover
@popover-background: @white;
@popover-border: 1px solid @grey[150];
@popover-box-shadow: @box-shadow[100];
@popover-border-radius: @border-radius[s];

.popover-container() {
  .transition-active(opacity, transform);

  background: @popover-background;
  border-radius: @popover-border-radius;
  border: @popover-border;
  box-shadow: @popover-box-shadow;
}

.popover-active() {
  opacity: 1;
  pointer-events: auto;
  transform: none;
}

.popover-hidden() {
  opacity: 0;
  pointer-events: none;
  transform: perspective(1000px) rotateX(-15deg);
}

// Intercom tokens
@intercom-color: @action-primary-background;
@intercom-size: 68px; // 56px of the icon + 12px of white space around

// Tables
@table-background: @white;
@table-border-radius: @border-radius[s];
@table-border: 1px solid @grey[200];

// Footer
@footer-background: @black;
@footer-text-primary-color: @white;
@footer-text-secondary-color: @grey[200];

// Modals
@modal-background: @white;
@modal-box-shadow: @box-shadow[600];
@modal-overlay-background: fade(@grey[600], 30%);
@modal-border-radius: @border-radius[xl-2];
@modal-border-mobile-radius: @border-radius[xl];
@modal-padding: @spacing[xl];
@modal-mobile-padding: @spacing[m];

// Touch targets
// See https://ferie.medium.com/detect-a-touch-device-with-only-css-9f8e30fa1134
@touch-only: ~"(hover: none) and (pointer: coarse)";
@mouse-only: ~"(hover: hover) and (pointer: fine)";

// Products
@product-block-gap: @spacing[xl-12];
@product-block-mobile-gap: @spacing[xl-10];

// Cart Item
@cart-item-background: @grey[50];
@cart-item-hover-background: @grey[75];
@cart-item-border: 4px solid @white;
@cart-item-modal-padding: @spacing[m];
@cart-item-mobile-modal-padding: @spacing[xs];
@cart-item-padding: @spacing[m] @cart-item-modal-padding;

// Lists
.list-reset() {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}

// Cards
@card-background: @white;

.card-container() {
  border-radius: @border-radius[l];
  background-color: @card-background;
}

.card-outline() {
  box-shadow: @box-shadow[100];
  border: @divider-border;
}

.card-unset() {
  border-radius: 0;
  box-shadow: none;
  border: none;
  background-color: @transparent;
}

.card-shadow() {
  .card-container();
  .card-outline();

  box-shadow: @box-shadow[300];
}

// Circle
.circle (@diameter) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: @diameter;
  width: @diameter;
}

// Tables
@table-background: @white;
@table-border-radius: @border-radius[m];
@table-header-background: @grey[75];
@table-border: @divider-border;

// Overflow
.text-overflow-ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Maps
@map-marker-color: @grey[700];

// Summary
@summary-background: @grey[25];

// Crossed Out
.crossed-out() {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 120%;
    height: 1px;
    transform: rotate(6deg);
    background: currentcolor;
    top: 50%;
    left: -10%;
  }
}

// Promotions
@promotion-banner-background: linear-gradient(
  157deg,
  @info[150] 0%,
  @promotion[150] 100%
);

// Select Panel
@select-panel-container-gap: @spacing[s];
