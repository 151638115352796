@import "./variables.less";

.container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 5px;
  background: linear-gradient(to right, @grey[800], @grey[600]);
  animation: load 10s normal forwards;
  transform: translateX(-100%);
  z-index: @z-index[loading-bar];
}

@keyframes load {
  0% {
    transform: translateX(-100%);
  }

  30% {
    transform: translateX(-60%);
  }

  100% {
    transform: translateX(0%);
  }
}
