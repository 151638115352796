@import "./variables.less";

@divider-border: 1px solid @grey[700];
@text-primary-color: @footer-text-primary-color;
@text-secondary-color: @footer-text-secondary-color;
@action-secondary-background: mix(@grey[700], @grey[800]);
@action-secondary-hover-background: @grey[600];

.angle {
  margin-right: @spacing[xs];
}

.button {
  .transition-hover(background);
  .font(m, bold);

  padding: @action-padding;
  background: @action-secondary-background;
  border-radius: @action-border-radius;
  height: @action-height-s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: @action-secondary-hover-background;

    .angle {
      .animation-angle-left-hover();
    }
  }
}

.main {
  padding: @spacing[xl-9] 0;
  background: @footer-background;
  color: @text-primary-color;
}

.container {
  display: flex;
  flex-direction: column;
  gap: @spacing[xl-11];
}

.content {
  display: grid;
  grid-template-columns: 1fr 330px;
  row-gap: @spacing[xl-5];
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: @spacing[xl-2];
}

.sites {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fine {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navs {
  .options {
    display: flex;
    flex-wrap: wrap;
    gap: @spacing[xl-11];
  }

  .accordion {
    display: none;
  }

  .nav {
    padding-right: @spacing[s];
    width: 200px;
    display: flex;
    flex-direction: column;
    gap: @spacing[xl];
  }

  .label {
    .font(m, bold);

    color: @text-primary-color;
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: @spacing[s];
  }

  .noBorder {
    border-bottom: none;
  }

  .item {
    .font(m, normal);
    .link-hover(@inverted-link-color);

    text-align: left;

    .angle {
      margin-right: 0;
      margin-left: @spacing[xs-2];
    }

    &:hover {
      .angle {
        .animation-angle-right-hover();
      }
    }
  }

  .all {
    .font(m, bold);
  }
}

.help {
  display: flex;
  flex-direction: column;
  gap: @spacing[xl-3];

  .label {
    .font(xl-2, normal);
  }

  .sublabel {
    .handwriting(xl-2);

    color: @text-secondary-color;
  }

  .links {
    display: flex;
    gap: @spacing[m];
  }

  .button {
    @action-border-radius: @border-radius[xl];

    border-radius: @action-border-radius;
    padding: 0;
    width: 72px;
    height: 64px;
    font-size: @font-sizes[xl-4];
  }

  .info {
    .font(m, normal);
  }

  .link {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: @spacing[s];

    &:hover {
      .button {
        background: @action-secondary-hover-background;
      }

      .icon {
        .animation-icon-wiggle();
      }
    }
  }

  .finePrint {
    .font(xs, normal);

    color: @text-secondary-color;
    display: flex;
    flex-direction: column;
    gap: @spacing[xs];
  }
}

.site {
  display: flex;
}

.nabp {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.legal {
  display: flex;
  gap: @spacing[xl-3];

  .legalLink {
    .font(xs, normal);
    .link-hover(@inverted-link-color);
  }
}

.copyright {
  display: flex;
  justify-content: flex-end;
  text-align: right;

  .text {
    .font(xs, normal);
  }
}

.disclaimer {
  .font(xs, normal);

  color: @text-secondary-color;
  border-top: @divider-border;
  padding-top: @spacing[l];
  display: flex;
  flex-direction: column;
  gap: @spacing[xs];
}

@media @tablet-portrait-and-smaller {
  .content {
    grid-template-columns: repeat(2, 1fr);
  }

  .navs {
    grid-column: 1 / 3;

    .options {
      display: none;
    }

    .accordion {
      display: block;
    }
  }

  .help {
    grid-column: 1 / 3;
  }

  .fine {
    flex-direction: column;
    gap: @spacing[s];
    align-items: flex-start;
  }

  .legal {
    flex-wrap: wrap;
    gap: @spacing[s];
  }
}

@media @phone-and-smaller {
  .legal {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .sites {
    .site {
      .button {
        width: 180px;
      }
    }
  }
}
