*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;

  /*
    While not totally recommended due to browser support, these font-smoothing settings are used
    widely at sites such as vercel.com to ensure fonts look the same in design as they do in
    the final implementation
  */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-weight: inherit;
}

ul,
ol {
  margin: 0;
  padding-left: 1.5rem;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

// Remove the slight flicker of gray when you click on buttons and links on mobile:
body {
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

// Remove unexpected outlines
body *:focus,
body *:focus-visible {
  outline: none;
}

// Reset fieldsets
legend {
  display: table;
  padding: 0;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0.01em 0 0;
}

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}

iframe {
  border: none;
}

input,
textarea,
[role="textbox"] {
  // Make sure the cursor shows up in windows computers as seen here:
  // https://jam.dev/c/1dd625ec-830c-47dd-8650-e4b6f76802fb
  cursor: text;
}
