@import "./variables.less";

.container {
  .font(s, normal);

  margin-top: @spacing[xs];
  color: @danger[500];
  display: flex;
}

.icon {
  margin-right: @spacing[xs-2];
  transform: translateY(
    3px
  ); // Pixel push to vertically line up with the first line of text
}
