@transition-duration: {
  faster: 20ms;
  fast: 40ms;
  base: 80ms;
};

.transition-hover(...) {
  @props: ~`"@{arguments}".replace(/[\[\]]/g, "")`;

  transition-duration: @transition-duration[faster];
  transition-timing-function: ease-in-out;
  transition-property: @props;
}

.transition-active(...) {
  @props: ~`"@{arguments}".replace(/[\[\]]/g, "")`;

  transition-duration: @transition-duration[fast];
  transition-timing-function: ease-in-out;
  transition-property: @props;
}
