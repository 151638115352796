@import "./variables.less";

.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  gap: @spacing[m];
  max-width: 600px;
}

.title {
  .font(xl-3, semi-bold);

  color: @text-primary-color;
}

.description {
  .font(m, normal);

  color: @text-secondary-color;
  max-width: 450px;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: @spacing[s];
}

.action {
  width: 320px;
}

@media @phone-and-smaller {
  .actions {
    flex-direction: column;
  }

  .action {
    max-width: 100%;
  }
}
