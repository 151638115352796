@import "../variables.less";

.btnStyles() {
  .font(m, medium);

  background-color: @grey[700];
  color: @white;
  border-radius: @border-radius[s];
  padding: @spacing[xs-2] @spacing[xs];
  box-shadow: @box-shadow[200];

  &:hover {
    background-color: @grey[800];
    box-shadow: @box-shadow[400];
  }
}

.panels {
  position: fixed;
  bottom: 0;
  z-index: 1000; // appear above everything
  width: 100%;
}

.head {
  display: flex;
  gap: @spacing[s];
  justify-content: space-between;
  padding: @spacing[xs];
}

.tabs {
  display: flex;
  flex-direction: row;
  gap: @spacing[s];
}

.tab {
  .btnStyles();

  &.active {
    background-color: @grey[900];
    box-shadow: @box-shadow[400];
  }
}

.content {
  position: relative;
}

.openBtn {
  .btnStyles();

  position: fixed;
  bottom: @spacing[s];
  left: @spacing[s];
  z-index: 1001;
}

.closeBtn {
  .btnStyles();
}
