@link-color: @accent[500];
@inverted-link-color: @accent[300];

.link-hover(@color: @link-color) {
  .transition-hover(color);

  &:not(:disabled) {
    &:hover,
    &:focus-visible {
      color: @color;
      // Help override when emphasis is used within
      * {
        color: @color;
      }
    }
  }

  &:disabled {
    opacity: @disabled-opacity;
  }
}

.link-reset() {
  color: inherit;

  &:not(:disabled) {
    &:hover,
    &:focus-visible {
      color: inherit;
    }
  }

  &:disabled {
    opacity: 1;
  }
}

.link(@color: @link-color) {
  .link-hover(@color);

  text-decoration: underline solid currentcolor;
}
