@import "./variables.less";

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: @spacing[xl] 0;
  text-align: left;
  gap: @spacing[xs];
}

.text {
  flex: 1;
}

.label {
  .font(m, medium);

  color: @text-primary-color;
}

.description {
  .font(s, normal);

  color: @text-secondary-color;
}

.item {
  &:not(:last-child) {
    border-bottom: @divider-border;
  }
}

.itemContent {
  padding-bottom: @spacing[xl];
}

.icon {
  font-size: 20px;
}

.header:hover {
  .icon {
    .animation-angle-down-hover();
  }
}
