@import "../variables.less";

:export {
  // Borders
  each(@border-radius, {
    border-radius-@{key}: @value;
  });

  // Breakpoints
  each(@breakpoints, {
    breakpoint-@{key}: @value;
  });

  // Colors
  white: @white;
  black: @black;
  brand: @brand;
  transparent: @transparent;
  highlight: @highlight;

  each(@grey, {
    grey-@{key}: @value;
  });

  each(@success, {
    success-@{key}: @value;
  });

  each(@warning, {
    warning-@{key}: @value;
  });

  each(@danger, {
    danger-@{key}: @value;
  });

  each(@info, {
    accent-@{key}: @value;
  });

  each(@promotion, {
    promotion-@{key}: @value;
  });

  each(@pastels, {
    @pastel: @@value;
    @color: @value;

    pastel-@{color}: @pastel[500];
  });

  // Fonts
  family: @font-family;
  handwriting-family: @handwriting-family;

  each(@font-sizes, {
    font-size-@{key}: @value;
  });

  each(@font-weights, {
    font-weight-@{key}: @value;
  });

  // Spacing
  each(@spacing, {
    spacing-@{key}: @value;
  });

  each(@transition-duration, {
    transition-duration-@{key}: @value;
  });
}
