@import "./variables.less";

.overlay {
  .transition-active(opacity);

  z-index: @z-index[presentation];
  position: fixed;
  inset: 0;
  height: 100%;
  min-height: fit-content;
  width: 100%;
  background: @transparent;

  .transition {
    &-entering,
    &-entered {
      opacity: 1;
    }

    &-exiting,
    &-exited {
      opacity: 0;
    }
  }

  &.visible {
    background: @modal-overlay-background;
  }
}
