@import "../variables.less";

.container {
  position: relative;
  width: 1em;
  height: 1em;
}

.rotator {
  width: 100%;
  height: 100%;
}

.angle {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 300ms;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    top: 50%;
    width: 50%;
    height: 10%;
    background-color: currentcolor;
    transition: all 300ms cubic-bezier(0.86, 0, 0.07, 1) 0s;
    transition-property: transform, transform-origin;
  }

  &::before {
    left: 0;
    border-radius: 4px 0 0 4px;
    transform: rotate(40deg) scaleY(1.2) translate(0, 0);
    transform-origin: 100% 100%;
  }

  &::after {
    right: 0;
    border-radius: 0 4px 4px 0;
    transform: rotate(-40deg) scaleY(1.2) translate(0, 0);
    transform-origin: 0% 100%;
  }
}

.active {
  .angle {
    &::before {
      transform: rotate(-40deg) scaleY(1.2) translate(5px, -5px);
      transform-origin: 100% 0%;
    }

    &::after {
      transform: rotate(40deg) scaleY(1.2) translate(-5px, -5px);
      transform-origin: 0% 0%;
    }
  }
}

.down {
  .rotator {
    transform: rotate(0deg);
  }
}

.left {
  .rotator {
    transform: rotate(90deg);
  }
}

.up {
  .rotator {
    transform: rotate(180deg);
  }
}

.right {
  .rotator {
    transform: rotate(270deg);
  }
}
