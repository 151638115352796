@import "./variables.less";

// right-align drawer
.layout {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 100vh;
  pointer-events: none;
}

.availableSpace {
  width: 540px;
}

.container {
  .transition-active(transform, opacity);

  z-index: @z-index[modal];
  position: relative;
  background: @modal-background;
  box-shadow: @modal-box-shadow;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-top-left-radius: @modal-border-radius;
  border-bottom-left-radius: @modal-border-radius;
  pointer-events: auto;
  height: 100%;

  &.transition {
    &-entering,
    &-entered {
      transform: translateX(0%);
    }

    &-exiting,
    &-exited {
      transform: translateX(120%);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
  }
}

.compact {
  &.layout {
    flex-direction: column;
  }

  .availableSpace {
    align-self: stretch;
    width: 100%;
    height: 90%;
  }

  .container {
    border-radius: 0;
    border-top-left-radius: @modal-border-mobile-radius;
    border-top-right-radius: @modal-border-mobile-radius;
    box-shadow:
      12px 0 40px -10px fade(@box-shadow-color, 10%),
      8px 0 18px -9px fade(@box-shadow-color, 10%);

    .transition {
      &-entering,
      &-entered {
        transform: translateY(0%);
      }

      &-exiting,
      &-exited {
        transform: translateY(120%);
      }
    }
  }
}
