@import "./variables.less";

.container {
  display: inline;

  &:hover {
    .angleRight {
      .animation-angle-right-hover();
    }

    .angleLeft {
      .animation-angle-left-hover();
    }

    .angleDown {
      .animation-angle-down-hover();
    }

    .any {
      .animation-icon-wiggle();
    }
  }
}

.preIcon {
  margin-right: @spacing[xs-2];
}

.postIcon {
  margin-left: @spacing[xs-2];
}

.variant- {
  &default {
    .link-hover();

    &.underline {
      .link();
    }
  }

  &semi-transparent {
    .link-hover(@grey[700]);

    &.underline {
      .link(@grey[700]);
    }
  }

  &dark {
    .link-hover(@inverted-link-color);

    &.underline {
      .link(@inverted-link-color);
    }
  }
}
