@import "./variables.less";

.body {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  text-align: center;
  padding: @spacing[xl] 0;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: @spacing[s];
  max-width: 500px;
  margin: 0 auto;
}

.action {
  max-width: 300px;
}

@media @phone-and-smaller {
  .actions {
    flex-direction: column;
  }

  .action {
    max-width: 100%;
  }
}
