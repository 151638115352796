@import "./variables.less";
@import "https://p.typekit.net/p.css?s=1&k=sku8ffl&ht=tk&f=24537.24538.24539.24540.24541.24542.24543.24544.24545.24546.24547.24548.24549.24550.24551.24552&a=20688374&app=typekit&e=css";

@font-face {
  font-family: sofia-pro;
  src:
    url("https://use.typekit.net/af/773a22/00000000000000007735f996/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/773a22/00000000000000007735f996/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/773a22/00000000000000007735f996/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  descent-override: 45%;
}

@font-face {
  font-family: sofia-pro;
  src:
    url("https://use.typekit.net/af/2cf3cc/00000000000000007735f991/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/2cf3cc/00000000000000007735f991/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/2cf3cc/00000000000000007735f991/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i9&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 900;
  font-stretch: normal;
  descent-override: 45%;
}

@font-face {
  font-family: sofia-pro;
  src:
    url("https://use.typekit.net/af/5d97ff/00000000000000007735f999/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/5d97ff/00000000000000007735f999/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/5d97ff/00000000000000007735f999/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  descent-override: 45%;
}

@font-face {
  font-family: sofia-pro;
  src:
    url("https://use.typekit.net/af/66e20c/00000000000000007735f993/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/66e20c/00000000000000007735f993/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/66e20c/00000000000000007735f993/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
  descent-override: 45%;
}

@font-face {
  font-family: sofia-pro;
  src:
    url("https://use.typekit.net/af/bb213c/00000000000000007735f995/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n2&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/bb213c/00000000000000007735f995/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n2&v=3")
      format("woff"),
    url("https://use.typekit.net/af/bb213c/00000000000000007735f995/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n2&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 200;
  font-stretch: normal;
  descent-override: 45%;
}

@font-face {
  font-family: sofia-pro;
  src:
    url("https://use.typekit.net/af/ceaf1c/00000000000000007735f98f/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i2&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/ceaf1c/00000000000000007735f98f/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i2&v=3")
      format("woff"),
    url("https://use.typekit.net/af/ceaf1c/00000000000000007735f98f/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i2&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 200;
  font-stretch: normal;
  descent-override: 45%;
}

@font-face {
  font-family: sofia-pro;
  src:
    url("https://use.typekit.net/af/2e2409/00000000000000007735f990/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/2e2409/00000000000000007735f990/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/2e2409/00000000000000007735f990/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  descent-override: 45%;
}

@font-face {
  font-family: sofia-pro;
  src:
    url("https://use.typekit.net/af/0ab956/00000000000000007735f998/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/0ab956/00000000000000007735f998/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/0ab956/00000000000000007735f998/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i3&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
  descent-override: 45%;
}

@font-face {
  font-family: sofia-pro;
  src:
    url("https://use.typekit.net/af/19ced7/00000000000000007735f992/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/19ced7/00000000000000007735f992/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/19ced7/00000000000000007735f992/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  descent-override: 45%;
}

@font-face {
  font-family: sofia-pro;
  src:
    url("https://use.typekit.net/af/33e8a4/00000000000000007735f99b/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/33e8a4/00000000000000007735f99b/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/33e8a4/00000000000000007735f99b/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i5&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
  descent-override: 45%;
}

@font-face {
  font-family: sofia-pro;
  src:
    url("https://use.typekit.net/af/b718ff/00000000000000007735f98d/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/b718ff/00000000000000007735f98d/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/b718ff/00000000000000007735f98d/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  descent-override: 45%;
}

@font-face {
  font-family: sofia-pro;
  src:
    url("https://use.typekit.net/af/c09857/00000000000000007735f98c/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/c09857/00000000000000007735f98c/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/c09857/00000000000000007735f98c/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  descent-override: 45%;
}

@font-face {
  font-family: sofia-pro;
  src:
    url("https://use.typekit.net/af/262452/00000000000000007735f99a/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/262452/00000000000000007735f99a/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/262452/00000000000000007735f99a/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  descent-override: 45%;
}

@font-face {
  font-family: sofia-pro;
  src:
    url("https://use.typekit.net/af/2dec1e/00000000000000007735f997/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n1&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/2dec1e/00000000000000007735f997/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n1&v=3")
      format("woff"),
    url("https://use.typekit.net/af/2dec1e/00000000000000007735f997/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n1&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 100;
  font-stretch: normal;
  descent-override: 45%;
}

@font-face {
  font-family: sofia-pro;
  src:
    url("https://use.typekit.net/af/ed1bc0/00000000000000007735f98e/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i1&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/ed1bc0/00000000000000007735f98e/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i1&v=3")
      format("woff"),
    url("https://use.typekit.net/af/ed1bc0/00000000000000007735f98e/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i1&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 100;
  font-stretch: normal;
  descent-override: 45%;
}

@font-face {
  font-family: sofia-pro;
  src:
    url("https://use.typekit.net/af/36f509/00000000000000007735f994/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/36f509/00000000000000007735f994/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/36f509/00000000000000007735f994/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i6&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 600;
  font-stretch: normal;
  descent-override: 45%;
}

@font-face {
  font-family: "Reenie Beanie";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/reeniebeanie/v16/z7NSdR76eDkaJKZJFkkjuvWxXPq1q6Gjb_0.woff2")
    format("woff2");
  /* stylelint-disable-next-line max-line-length */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Azeret Mono";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/azeretmono/v17/3XFuErsiyJsY9O_Gepph-HHvZfn23vRKV0U.woff2")
    format("woff2");
  /* stylelint-disable-next-line max-line-length */
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Azeret Mono";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/azeretmono/v17/3XFuErsiyJsY9O_Gepph-HHhZfn23vRK.woff2")
    format("woff2");
  /* stylelint-disable-next-line max-line-length */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Azeret Mono";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/azeretmono/v17/3XFuErsiyJsY9O_Gepph-HHvZfn23vRKV0U.woff2")
    format("woff2");
  /* stylelint-disable-next-line max-line-length */
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Azeret Mono";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/azeretmono/v17/3XFuErsiyJsY9O_Gepph-HHhZfn23vRK.woff2")
    format("woff2");
  /* stylelint-disable-next-line max-line-length */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Azeret Mono";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/azeretmono/v17/3XFuErsiyJsY9O_Gepph-HHvZfn23vRKV0U.woff2")
    format("woff2");
  /* stylelint-disable-next-line max-line-length */
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Azeret Mono";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/azeretmono/v17/3XFuErsiyJsY9O_Gepph-HHhZfn23vRK.woff2")
    format("woff2");
  /* stylelint-disable-next-line max-line-length */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
