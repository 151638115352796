@import "./variables.less";

@line-width: 2px;

.icon {
  pointer-events: none;
  width: 20px;
  height: 20px;
  color: @input-placeholder-color;
}

.lineClose,
.lineMain,
.circle {
  .transition-active(width, opacity, transform);
}

.lineMain,
.lineClose {
  position: absolute;
  background-color: currentcolor;
  border-radius: 4px;
  height: @line-width;
}

.lineMain {
  width: 40%;
  transform: rotate(45deg);
  transform-origin: right bottom;
  background-color: currentcolor;
  bottom: 13%;
  right: 15%;
}

.lineClose {
  width: 0%;
  transform: rotate(-45deg);
  transform-origin: right top;
  background-color: currentcolor;
  top: 9%;
  right: 17%;
}

.circle {
  position: absolute;
  border-radius: 50%;
  border: @line-width solid currentcolor;
  height: 64%;
  width: 64%;
  top: 4%;
  left: 10%;
}

&.active {
  .hover-expand-background(36px, @action-secondary-hover-background);

  pointer-events: initial;

  .circle {
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  .lineMain {
    width: 100%;
  }

  .lineClose {
    width: 100%;
  }
}

.visuallyHidden {
  .visually-hidden();
}
