@import "./variables.less";

.close {
  .hover-expand-background(52px, @action-secondary-hover-background, @border-radius[l]);

  // New stacking context
  position: relative;
  z-index: @z-index[default];
  font-size: @font-sizes[xl-3];
  color: @text-primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media @phone-and-smaller {
  .close {
    .hover-expand-background(44px, @action-secondary-hover-background, @border-radius[m]);

    font-size: @font-sizes[xl-2];
  }
}
