@import "./variables.less";

.main {
  padding: @body-padding;
}

@media @phone-and-smaller {
  .main {
    padding: @spacing[l] 0;
  }
}
