@import "./variables.less";

.container {
  .transition-hover(background, border-color, opacity);

  width: max-content;
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  &:not(.isLoading):disabled {
    opacity: @disabled-opacity;
  }
}

.spinner {
  .transition-hover(opacity);

  position: absolute;
  opacity: 0;
}

.label {
  .transition-hover(opacity);

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 1;
}

.isLoading {
  .spinner {
    opacity: 1;
  }

  .label {
    opacity: 0;
  }
}

.variant(@name, @default, @hover) {
  .container.variant-@{name} {
    @default();

    &:not(:disabled):hover {
      @hover();
    }
  }
}

.size-m {
  .font(m, medium);

  height: @subaction-height-m;
  border-radius: @subaction-height-m;
  padding: 0 @spacing[xl];
}

.size-s {
  .font(s, medium);

  height: @subaction-height-s;
  border-radius: @subaction-height-s;
  padding: 0 @spacing[m];
}

.size-xs {
  .font(xs, medium);

  height: @subaction-height-xs;
  border-radius: @subaction-height-xs;
  padding: 0 @spacing[s];
}

.variant(
  primary,
  @default: {
    color: @action-primary-text-color;
    background: @action-primary-background;
  },
  @hover: {
    background: @action-primary-hover-background;
  }
);

.variant(
  secondary,
  @default: {
    border: @action-secondary-border;
    color: @action-secondary-text-color;
    background: @action-secondary-background;
  },
  @hover: {
    background: @action-secondary-hover-background;
    border: @action-secondary-hover-border;
  }
);

.variant(
  tertiary,
  @default: {
    color: @action-tertiary-text-color;
    background: @action-tertiary-background;
  },
  @hover: {
    background: @action-tertiary-hover-background;
  }
);

@media @phone-and-smaller {
  .container {
    min-width: 0;
  }
}
