@import "./variables.less";

.button {
  .transition-hover(border-color, background, opacity);

  border-radius: @action-border-radius;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:not(.isLoading):disabled {
    opacity: @disabled-opacity;
  }
}

.variant(@name, @default, @hover) {
  .button.variant-@{name} {
    @default();

    &:not(:disabled):hover {
      @hover();
    }
  }
}

.variant(
  primary,
  @default: {
    color: @action-primary-text-color;
    background: @action-primary-background;
  },
  @hover: {
    background: @action-primary-hover-background;
  }
);

.variant(
  secondary,
  @default: {
    border: @action-secondary-border;
    color: @action-secondary-text-color;
    background: @action-secondary-background;
  },
  @hover: {
    background: @action-secondary-hover-background;
    border: @action-secondary-hover-border;
  }
);

.spinner {
  .transition-hover(opacity);

  position: absolute;
  opacity: 0;
}

.label {
  .transition-hover(opacity);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: @spacing[xs];
  text-align: center;
  opacity: 1;
}

.isLoading {
  .spinner {
    opacity: 1;
  }

  .label {
    opacity: 0;
  }
}

.size-m {
  .font(m, semi-bold);

  min-height: @action-height-m;
  padding: 0 @spacing[l];
}

.size-s {
  .font(s, semi-bold);

  min-height: @action-height-s;
  padding: 0 @spacing[m];
}

.size-xs {
  .font(xs, semi-bold);

  min-height: @action-height-xs;
  padding: 0 @spacing[m];
}

@media @phone-and-smaller {
  .container {
    min-width: 0;
  }
}
