@import "../variables.less";

.spinner {
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  animation: spin 0.75s linear infinite;
}
