@import "../../common/styles/variables.less";

// Wrap our main LESS variables and export them for use with any components
// that require styles be passed in directly or are otherwise incompatible
// with our CSS modules

// TODO: Migrate away from this and import specific token files in components
:export {
  name: "less";
  // Colors
  textPrimaryColor: @text-primary-color;
  // Fonts
  fontUrl: @font-url;
  fontFamilyPrimary: @font-family;
  fontFamily: @font-family;
  fontWeight: @font-weights[normal];
  fontSize: 16px;
  // Alias
  mapMarkerColor: @map-marker-color;
  // Intercom
  intercomColor: @intercom-color;
}
