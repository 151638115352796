@import "./variables.less";

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  z-index: @z-index[header];

  &.white {
    filter: brightness(0) invert(1);
  }
}

.logo {
  max-height: 70px;
  max-width: 160px;
}

.textLogo {
  .title {
    .font(m, bold);

    margin: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
    max-width: 160px;
  }

  .subtitle {
    .font(xs, medium);

    margin: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

@media @tablet-portrait-and-smaller {
  .container {
    justify-content: center;
  }

  .logo {
    max-height: 48px;
    max-width: 120px;
  }
}
