// Override on an app to app basis
@z-index: {
  default: 0; // Default to help with new stacking contexts
};

// Prettier puts a space between the $ and the @ when dereferencing map variables
// which leads to syntax errors. See https://github.com/prettier/prettier/issues/14814
// for more context.

/* prettier-ignore */
.new-stacking-context(@index: default) {
  position: relative;
  z-index: @z-index[$@index];
}
