@import "./variables.less";

.container {
  .transition-active(opacity);

  z-index: @z-index[presentation];
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: fit-content;
  width: 100%;
  background: @modal-overlay-background;

  .transition {
    &-entering,
    &-entered {
      opacity: 1;
    }

    &-exiting,
    &-exited {
      opacity: 0;
    }
  }
}

.modal {
  .transition-active(opacity, transform);

  display: flex;
  flex-direction: column;
  z-index: @z-index[modal];
  top: 50%;
  left: 50%;
  overflow-y: auto;
  width: 90%;
  height: 86%;
  background: @modal-background;
  box-shadow: @modal-box-shadow;
  border-radius: 3px;
  transform: translate(-50%, -50%), scale(0.6);

  &.transition {
    &-entering,
    &-entered {
      opacity: 1;
      transform: scale(1);
    }

    &-exiting,
    &-exited {
      opacity: 0;
      transform: scale(0.6);
    }
  }
}
