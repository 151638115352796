// FONTS
// Applied via Adobe's Typekit
@font-family: "sofia-pro", sans-serif;
// Applied via Google Fonts
@handwriting-family: "Reenie Beanie", cursive;
@mono-family: "Azeret Mono", monospace;

@font-url: "https://use.typekit.net/sku8ffl";
@handwriting-url: "https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap";
@mono-url: "https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@400;500;600&display=swap";

@font-sizes: {
  xs-2: 10px;
  xs: 12px;
  s: 14px;
  m: 16px;
  l: 18px;
  xl: 20px;
  xl-2: 24px;
  xl-3: 30px;
  xl-4: 36px;
  xl-5: 48px;
  xl-6: 60px;
  xl-7: 72px;
  xl-8: 96px;
  xl-9: 128px;
};

@mobile-font-sizes: {
  xs-2: @font-sizes[xs-2];
  xs: @font-sizes[xs];
  s: @font-sizes[s];
  m: @font-sizes[s];
  l: @font-sizes[m];
  xl: @font-sizes[l];
  xl-2: @font-sizes[xl];
  xl-3: @font-sizes[xl-2];
  xl-4: @font-sizes[xl-2];
  xl-5: @font-sizes[xl-3];
  xl-6: @font-sizes[xl-3];
  xl-7: @font-sizes[xl-3];
  xl-8: @font-sizes[xl-3];
  xl-9: @font-sizes[xl-3];
};

@line-heights: {
  xs-2: 1.2;
  xs: 1.33;
  s: 1.43;
  m: 1.5;
  l: 1.56;
  xl: 1.4;
  xl-2: 1.33;
  xl-3: 1.2;
  xl-4: 1.2;
  xl-5: 1.2;
  xl-6: 1.2;
  xl-7: 1.2;
  xl-8: 1.2;
  xl-9: 1.2;
};

@font-weights: {
  ultra-light: 100;
  extra-light: 200;
  light: 300;
  normal: 400;
  medium: 500;
  semi-bold: 600;
  bold: 700;
  black: 800;
};

// Prettier puts a space between the $ and the @ when dereferencing map variables
// which leads to syntax errors. See https://github.com/prettier/prettier/issues/14814
// for more context.

/* prettier-ignore */
.font(@weight) {
  font-family: @font-family;
  font-weight: @font-weights[$@weight];
}

// Prettier puts a space between the $ and the @ when dereferencing map variables
// which leads to syntax errors. See https://github.com/prettier/prettier/issues/14814
// for more context.

/* prettier-ignore */
.font(@size, @weight) {
  @font-size: @font-sizes[$@size];

  font-family: @font-family;
  font-size: @font-size;
  line-height: @line-heights[$@size];
  font-weight: @font-weights[$@weight];

  // Automatically shrink down fonts for mobile
  @media @phone-and-smaller {
    font-size: @mobile-font-sizes[$@size];
  }
}

// Prettier puts a space between the $ and the @ when dereferencing map variables
// which leads to syntax errors. See https://github.com/prettier/prettier/issues/14814
// for more context.

/* prettier-ignore */
.handwriting(@size) {
  font-family: @handwriting-family;
  font-size: @font-sizes[$@size];
  line-height: @line-heights[$@size];
}

// Prettier puts a space between the $ and the @ when dereferencing map variables
// which leads to syntax errors. See https://github.com/prettier/prettier/issues/14814
// for more context.

/* prettier-ignore */
.mono(@size, @weight) {
  font-family: @mono-family;
  font-size: @font-sizes[$@size];
  line-height: @line-heights[$@size];
  font-weight: @font-weights[$@weight];
}

.font-label() {
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
