@import "./variables.less";

.container {
  display: flex;
  border-radius: @border-radius[s]; // TODO: Use Banner component
  padding: @spacing[xs] @spacing[m];
  align-items: flex-start;
  gap: @spacing[s];
  position: relative;
}

.content {
  display: flex;
  gap: @spacing[xs-2] @spacing[s];
  flex: 1;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
}

.icon {
  margin-top: 2px; // Pixel push for alignment with the first line
  font-size: @font-sizes[m];
}

.description {
  .font(s, semi-bold);

  text-align: start;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: @spacing[xs];
}

.action {
  .font(xs, normal);

  flex-shrink: 0;
  padding: @spacing[xs-3] 0;
}

.variant-color(@classname, @background-color, @color) {
  .@{classname} {
    &.container {
      background-color: @background-color;
    }

    .icon {
      color: @color;
    }

    .description {
      color: @color;
    }

    .action {
      color: @color;
    }
  }
}

.variant-color(info, @grey[75], @grey[800]);
.variant-color(success, @success[75], @success[500]);
.variant-color(warning, @warning[75], @warning[500]);
.variant-color(danger, @danger[75], @danger[500]);
