@import "../../common/styles/variables.less";

@chip-border-radius: @border-radius[xl];

.chip {
  border-radius: @chip-border-radius;
  line-height: 1.6;

  &.size- {
    &s {
      .font(xs-2, medium);

      &:not(.multiline, .inline) {
        height: @chip-height[s];
      }
    }

    &m {
      .font(xs, medium);

      &:not(.multiline, .inline) {
        height: @chip-height[m];
      }
    }
  }

  &.inline {
    @font-size: 0.8em;

    display: inline-flex;
    align-items: center;
    gap: @spacing[xs-2];
    font-size: @font-size;
    font-weight: @font-weights[medium];
    line-height: inherit;
    padding: (1em - @font-size) 0.5em;
  }

  &:not(.inline) {
    display: flex;
    align-items: center;
    gap: @spacing[xs-2];
    padding: 0.25em 1em;
  }

  &:not(.multiline) {
    width: min-content;
    white-space: nowrap;
  }
}

.variant(@name, @background-color, @color) {
  .variant-@{name} {
    background-color: @background-color;
    color: @color;
  }
}

.variant(default, @grey[75], @grey[700]);
.variant(grey, @grey[150], @grey[900]);
.variant(primary, @black, @white);
.variant(promotion, @promotion[150], @black);
.variant(promotion-fill, @promotion[500], @white);
.variant(warning, @warning[75], @warning[500]);
.variant(highlight, @highlight, @white);
.variant(info, @info[500], @white);
.variant(transparent, fade(@white, 25%), @white);
