@import "../variables.less";

.content {
  .transition-hover(opacity);
}

.collapse {
  will-change: height; // height is more expensive than transforms
  transition: all @transition-duration[faster] cubic-bezier(0.28, 0.13, 0.38, 1)
    0s;
  transition-property: height;

  &[aria-hidden="false"] {
    .content {
      opacity: 1;
    }
  }

  &[aria-hidden="true"] {
    .content {
      opacity: 0;
    }
  }
}
