@import "./variables.less";

.container {
  background: @transparent;
}

.banner {
  position: relative;
  z-index: @z-index[header];
}

.content {
  display: grid;
  grid-template-columns: 350px 1fr 350px;
  gap: @spacing[xl];
  align-items: center;
  padding-top: @spacing[m];
}

.drawer {
  display: none;
}

.navigation {
  position: relative;
  z-index: @z-index[header];
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: @spacing[xl];
}

.account {
  position: relative;

  .popover {
    .popover-container();
    .popover-hidden();

    position: absolute;
    width: 228px;
    right: 0;
    padding: @spacing[m];
    z-index: @z-index[dropdown];

    &.open {
      .popover-active();

      transform: translateY(@spacing[m]);
    }

    .client {
      display: flex;
      gap: @spacing[xs];

      .linkIcon {
        transform: translateY(5px); // Pixel push to alignment with name
      }

      .name {
        .font(m, medium);

        color: @text-primary-color;
      }

      .phone {
        .font(s, medium);

        color: @text-secondary-color;
      }
    }

    .links {
      display: flex;
      flex-direction: column;
      gap: @spacing[xs];
      padding: @spacing[m] 0;
      border-bottom: @divider-border;
      margin-bottom: @spacing[m];
    }

    .subnav {
      .font(m, medium);
      .link-hover();

      color: @text-primary-color;
      display: flex;
      gap: @spacing[xs];
      align-items: center;

      .linkIconContainer {
        transform: translateY(1px); // Pixel push to alignment with link
      }

      &:hover {
        .linkIcon {
          .animation-icon-wiggle();
        }
      }
    }

    .prescriptions {
      display: none;
    }
  }
}

.nav {
  .font(m, medium);
  .hover-expand-background(2em, fadecolor(@action-secondary-hover-background, 10%));
  .new-stacking-context();

  color: @text-primary-color;

  &::after {
    border-radius: 46px;
    height: 46px;
    width: calc(100% + 32px);
  }
}

.cart {
  .font(m, medium);
  .transition-hover(background);

  display: flex;
  align-items: center;
  gap: @spacing[xs];
  border-radius: @action-height-s;
  height: @action-height-s;
  padding: 0 @spacing[m];
  background: @action-primary-background;
  color: @action-primary-text-color;

  &:hover {
    background: @action-primary-hover-background;
  }
}

.searchbar {
  min-width: 230px;
  position: relative;
  z-index: @z-index[header];
}

.search {
  position: relative;
  max-width: 500px;
  margin: 0 auto;

  .placeholder {
    .text-overflow-ellipsis();

    user-select: none;
    color: @input-placeholder-color;
  }
}

.input {
  .input-reset();
  .input-text();
  .transition-hover(background, box-shadow);
  .text-overflow-ellipsis();
  .input-default();

  flex: 1;
  height: @action-height-s;
  border-radius: @action-height-s;
  display: flex;
  align-items: center;
  padding: 0 @spacing[xl-9] 0 @spacing[xl]; // Give padding for the icon
  position: relative;
  width: 100%;

  &::placeholder {
    color: @input-placeholder-color;
  }

  &:hover {
    .input-hover();
  }

  // .autoFocus and .dynamic help ensure a smooth transition from random pages to the search page
  &.autoFocus {
    .input-hover();
  }

  &.dynamic {
    &:focus {
      .input-focus();
    }
  }
}

.icon {
  position: absolute;
  right: @spacing[xl];
  top: 50%;
  transform: translateY(-50%);
}

@media @tablet-landscape-and-smaller {
  .content {
    grid-template-columns: 1fr 2fr 1fr;
  }
}

@media @tablet-portrait-and-smaller {
  .drawer {
    display: flex;
  }

  .searchbar,
  .nav,
  .account {
    display: none;
  }

  .search {
    max-width: 100%;
  }

  .content {
    grid-template-columns: 1fr 3fr 1fr;
    padding: @spacing[s] 0;
  }

  .cart {
    .font(s, medium);

    height: 28px;
    padding: 0 @spacing[xs];
    gap: @spacing[xs-2];
  }
}
