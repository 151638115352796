@import "./variables.less";

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &.standalone {
    z-index: @z-index[standalone-stackable];
  }
}

.box {
  .transition-active(transform, opacity);

  z-index: @z-index[modal];
  position: relative;
  background: @modal-background;
  box-shadow: @modal-box-shadow;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 540px;
  border-radius: @modal-border-mobile-radius;

  &.transition {
    &-entering,
    &-entered {
      transform: scale(1);
      opacity: 1;
    }

    &-exiting,
    &-exited {
      transform: scale(0.7);
      opacity: 0;
    }
  }
}
