@import "./variables.less";

.content {
  display: flex;
  flex-direction: column;
  gap: @spacing[xl];
  flex: 1;
  overflow-y: auto;
  padding: @spacing[xl] @spacing[xl-3];
}

.text {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: @spacing[xs];
}

.title {
  .font(xl-3, semi-bold);

  color: @text-primary-color;
}

.description {
  .font(m, normal);

  color: @text-secondary-color;
}

.actions {
  display: flex;
  gap: @spacing[m];
}

@media @phone-and-smaller {
  .content {
    padding: @spacing[m];
    gap: @spacing[l];
  }

  .text {
    gap: @spacing[s];
  }

  .actions {
    gap: @spacing[s];
    flex-direction: column-reverse;
  }
}
